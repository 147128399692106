import React from "react"
import Image from "material-ui-image"

import "./home.css.less"
import { MainLayout } from 'layouts/main'
import { Grid } from "@material-ui/core";

const HomePage = () => {

    return <MainLayout>
        <div className="home-page">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{height:"100vh"}}
            >
                <Grid item>
                    Bebz Barry Abonales dot me!
                </Grid>
            </Grid>
        </div>
    </MainLayout>
}

export { HomePage }