import { HomePage } from "pages/home";
import React from "react"
import {Switch ,Route, Redirect} from "react-router-dom"

function App() {
  return (
    <Switch>
      <Redirect exact from="/" to={`/home`} />
      <Route path={`/home`}>
        <HomePage />
      </Route>
    </Switch>
  );
}

export default App;
